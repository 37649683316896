const host = window.location.host;
//* 如果想要在本地调试线上环境，手动取消下面这行代码的注释：
// const isProd = true;
// const isProd = host === "www.knowledgesource.cn";
const isProd = host === "http://cip.cxstar.cn";
//! 但一定要在推送代码/打包时改回到上面这行代码！
// const localUrl = isProd ? 'http://cip.temp.cxstar.cn' : 'http://cip.temp.cxstar.cn'
//const baseUrl = isProd ? "http://cipread.golden-prairie.com" : "http://cipread.golden-prairie.com";
// const localUrl = isProd ? 'http://cip.cxstar.cn' : 'http://cip.cxstar.cn'
// const baseUrl = isProd ? 'http://cip.cxstar.cn' : 'http://cip.cxstar.cn'
// const baseUrl = isProd
//   ? "http://192.168.0.182:5555"
//   : "http://192.168.0.4:5588";
// const localUrl = `${window.location.host}`;
const baseUrl = `${window.location.origin}`;
// const baseUrl = `http://cip.cxstar.cn`;

const fileUrl = isProd ? "https://file.knowledgesource.cn" : "https://file.cxstar.cn";
//const readUrl = "http://cipreader.golden-prairie.com";
const readUrl = `${window.location.origin}/reader`;
export default { baseUrl, fileUrl, readUrl };
