<template>
  <div class="container flex">
    <div class="w1200">
      <div class="flex pointer hoverTouch" style="align-items: center">
        <img style="height: 18px; width: 18px" @mouseenter="classifyHover" @mouseleave="classifyFlag = false" :src="classifyImged" />
        <span @mouseenter="classifyHover" @mouseleave="classifyFlag = false" style="color: #fff; margin-left: 5px; font-size: 18px" class="tsflspan">图书分类</span>
      </div>
      <!-- <img src="../../assets/images/txt.png" alt="" /> -->
      <div class="search flex">
        <div class="searchForm flex">
          <el-select v-model="searchForm.searchField" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <div style="width: 1px; height: 22px; background: #0188ef">|</div>
          <el-input clearable maxlength="30" @keyup.enter.native="handleSearch" v-model="searchForm.searchWord" placeholder="请输入要检索的内容"></el-input>
          <img @click="handleSearch" src="../../assets/images/icon-search.png" />
        </div>
        <img @click="handleHighSearch" class="heightSearch" src="../../assets/images/btn-gjjs-default.png" alt="" />
      </div>
      <div class="list">
        <ul class="flex mb15">
          <li @click="handleClickFirstClassify(subjectCount['科技'])">
            <div class="num">
              <p>科技</p>
              <p>资源量：{{ subjectCount["科技"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-kj.png" />
          </li>
          <li @click="handleClickFirstClassify(subjectCount['教材'])">
            <div class="num">
              <p>教材</p>
              <p>资源量：{{ subjectCount["教材"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-jc.png" />
          </li>
          <li @click="handleClickFirstClassify(subjectCount['生活'])">
            <div class="num">
              <p>生活</p>
              <p>资源量：{{ subjectCount["生活"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-sh.png" />
          </li>
          <li @click="handleClickFirstClassify(subjectCount['少儿'])">
            <div class="num">
              <p>少儿</p>
              <p>资源量：{{ subjectCount["少儿"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-shaoer.png" />
          </li>
        </ul>
        <ul class="flex">
          <li @click="handleClickFirstClassify(subjectCount['社科'])">
            <div class="num">
              <p>社科</p>
              <p>资源量：{{ subjectCount["社科"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-sk.png" />
          </li>
          <li @click="handleClickFirstClassify(subjectCount['文艺'])">
            <div class="num">
              <p>文艺</p>
              <p>资源量：{{ subjectCount["文艺"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-wy.png" />
          </li>
          <li @click="handleClickFirstClassify(subjectCount['语言'])">
            <div class="num">
              <p>语言</p>
              <p>资源量：{{ subjectCount["语言"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-yy.png" />
          </li>

          <li @click="handleClickFirstClassify(subjectCount['中小学教辅'])">
            <div class="num">
              <p>中小学教辅</p>
              <p>资源量：{{ subjectCount["中小学教辅"].count }}</p>
            </div>
            <div class="hoverBg"></div>
            <img src="../../assets/images/bj-zh.png" />
          </li>
        </ul>
      </div>
    </div>
    <!-- 分类 @mouseout="classifyOut" -->
    <div class="classify" v-show="classifyFlag" @mouseenter="classifyHover" @mouseleave="classifyFlag = false" :style="classifyStyle">
      <div class="w1200">
        <ul>
          <li class="flex oneClass" @click.self="handleClick(item, `全部图书 > ${item.name}`, 0, index)" @mouseenter="changeImg(item.name, index)" @mouseleave="outImg(item.name, index)" v-for="(item, index) in classifyList" :key="index">
            <!-- <img :src="getImg(item.name)"/>{{item.name}} -->
            <img :src="getImage(item.name, index)" />
            {{ item.name }}<i v-if="item.children" class="el-icon-arrow-right"></i>
            <div class="twoClass">
              <div class="twoClassContent" ref="menu" @scroll="mouseScroll">
                <div class="two-item" v-for="(element, idx) in item.children" :key="idx" @click.self.stop="handleClick(element, `全部图书 > ${item.name} > ${element.name}`, 0, index, idx)">
                  <span class="line1">{{ element.name }}</span>
                  <i v-if="element.children" class="el-icon-arrow-right"></i>
                  <div class="threeClass">
                    <div class="flex">
                      <span @click.stop="handleClick(list, `全部图书 > ${item.name} > ${element.name} > ${list.name}`, 0, index, idx, threeidx)" v-for="(list, threeidx) in element.children" :key="threeidx">{{ list.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- <div class="question-mask" v-show="seeAllNotice">
            <span>︾</span>
          </div> -->
        </ul>
      </div>
    </div>
    <!--  底部  -->
    <div class="el-login-footer">
      <p style="opacity: 0.5">
        {{ footerForm.configValue }}
        <!-- 化学工业出版社有限公司 版权所有 |
        联系电话：010-64519105（座机）；18600895080（手机） -->
      </p>
      <p>
        <span style="opacity: 0.5" @click="handleOpenLicence" class="linkurl">网络出版服务许可证</span>
        <img style="vertical-align: middle; margin-right: 5px" src="@/assets/images/wj.png" />
        <span style="opacity: 0.5" @click="handleOpenArchival(1)" class="linkurl">京公网安备 11010102002361号</span>
        <span style="opacity: 0.5" @click="handleOpenArchival(2)" class="linkurl">京ICP备12046843号-10</span>
      </p>
    </div>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="openUpdateView" width="450px" append-to-body :show-close="false">
      <p class="update-red"><i class="el-icon-warning"></i>您的密码已经90天未修改，请重新修改密码！</p>
      <el-form ref="updateForm" :model="updateForm" :rules="updateRules" class="update-form">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input type="password" v-model="updateForm.oldPassword" maxlength="20" placeholder="请输入旧密码" autocomplete="off"></el-input>
        </el-form-item>
        <p class="tip">*密码必须包含大小写字母、数字和特殊符号4类字符中至少3种类型</p>
        <el-form-item label="新密码" prop="newPassword">
          <el-input placeholder="请输入新密码" maxlength="20" type="password" v-model="updateForm.newPassword" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="确认新密码" prop="requirePassword">
          <el-input placeholder="请输入确认新密码" type="password" maxlength="20" v-model="updateForm.requirePassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitUpdatePassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { indexSubjectList } from "@/api/home.js";
import { indexSubjectCount } from "@/api/home.js";
import { getPublicKey, UpdatePwd } from "@/api/login";
import { handleTree } from "@/utils/cxstar";
import licenseImg from "@/assets/images/wlcbfwxkz.jpg";
import { list } from "@/api/setting.js";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import JSEncrypt from "jsencrypt";
let Base64 = require("js-base64").Base64;
import store from "@/store";
export default {
  data() {
    var checkRequirePassword = (rule, value, callback) => {
      if (value) {
        if (this.updateForm.requirePassword != this.updateForm.newPassword) {
          callback(new Error("两次密码输入不一致"));
        } else {
          callback();
        }
      }
    };
    var checkNewPassword = (rule, value, callback) => {
      if (value) {
        let regx = /^[A-Za-z0-9@#$%^&*]+$/;
        var exppwd = /((^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8,16}$)|(^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8,16}$)|(^(?=.*\d)(?=.*[a-z])(?=.*\W)[\da-zA-Z\W]{8,16}$)|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z\W]{8,16}$))/;
        if (!regx.test(value)) {
          callback(new Error("请输入英文字母或数字或@#$%^&*"));
        } else if (!exppwd.test(value)) {
          callback(new Error("密码必须包含大小写字母、数字和特殊符号4类字符中至少3种类型"));
        } else {
          callback();
        }
      }
    };
    return {
      footerForm: "化学工业出版社有限公司 版权所有 |  联系电话：010-64519105（座机）；18600895080（手机）",
      classifyList: [],
      quanbu: require("@/assets/images/icon-qb-default.png"),
      quanbued: require("@/assets/images/icon-qb-select.png"),
      keji: require("@/assets/images/icon-keji-default.png"),
      kejied: require("@/assets/images/icon-keji-select.png"),
      jiaocai: require("@/assets/images/icon-jiaocai-default.png"),
      jiaocaied: require("@/assets/images/icon-jiaocai-select.png"),
      shenghuo: require("@/assets/images/icon-shenghuo-default.png"),
      shenghuoed: require("@/assets/images/icon-shenghuo-select.png"),
      shaoer: require("@/assets/images/icon-shaoer-default.png"),
      shaoered: require("@/assets/images/icon-shaoer-select.png"),
      sheke: require("@/assets/images/icon-sheke-default.png"),
      shekeed: require("@/assets/images/icon-sheke-select.png"),
      wenyi: require("@/assets/images/icon-wenyi-default.png"),
      wenyied: require("@/assets/images/icon-wenyi-select.png"),
      yuyan: require("@/assets/images/icon-yuyan-default.png"),
      yuyaned: require("@/assets/images/icon-yuyan-select.png"),
      zonghe: require("@/assets/images/icon-zonghe-default.png"),
      zongheed: require("@/assets/images/icon-zonghe-select.png"),
      // classifyImg: require("@/assets/images/icon-class-default.png"),
      // classifyImged: require("@/assets/images/icon-class-select.png"),
      classifyImg: require("@/assets/images/fl.png"),
      classifyImged: require("@/assets/images/fl.png"),
      classifyFlag: false,
      seeAllNotice: false,
      treeHeight: 420,
      searchForm: {
        searchWord: "", //检索词
        searchBarWord: "", //检索条-检索词 字段默认为题名、作者、ISBN
        searchField: "title,author", //检索字段，先固定值
        subjectIds: [], //左侧学科
        clcIds: [], //左侧中图法
        publishYear: null, //出版年
        pageNum: 1, //页码 1
        pageSize: 10, //页面大小 10
        orderByColumn: "state_time", //排序字段 默认出版日期
        isAsc: "desc", //排序方式 默降序排序
      },
      subjectCount: {
        科技: { count: 0 },
        教材: { count: 0 },
        生活: { count: 0 },
        少儿: { count: 0 },
        社科: { count: 0 },
        文艺: { count: 0 },
        语言: { count: 0 },
        中小学教辅: { count: 0 },
      },
      options: [
        {
          value: "title,author",
          label: "全部",
        },
        {
          value: "title",
          label: "书名",
        },
        {
          value: "author",
          label: "作者",
        },
        {
          value: "isbn",
          label: "ISBN",
        },
      ],
      value: "title,author",
      openUpdateView: false,
      updateForm: {
        oldPassword: "",
        newPassword: "",
        requirePassword: "",
      },
      updateRules: {
        oldPassword: [{ required: true, trigger: "blur", message: "请输入旧密码" }],
        newPassword: [
          { required: true, trigger: "blur", message: "请输入新密码" },
          { validator: checkNewPassword, trigger: "blur" },
        ],
        requirePassword: [
          { required: true, trigger: "blur", message: "请输入确认新密码" },
          { validator: checkRequirePassword, trigger: "blur" },
        ],
      },
      tipsMsg: "",
    };
  },
  created() {
    if (sessionStorage.getItem("updatePassword") == "true" || sessionStorage.getItem("updatePassword") == true) {
      this.openUpdateView = true;
    }
    this.getData();
    this.$store.dispatch("resetField");
    this.getDetailByType("sys.footer.info");
  },
  mounted() {},
  computed: {
    classifyStyle() {
      const height = this.treeHeight;
      return { height: `${height}px` };
    },
  },
  methods: {
    // 获取页脚详情
    getDetailByType(type) {
      list(type).then(res => {
        if (type == "sys.footer.info") {
          this.footerForm = res.rows[0];
        }
      });
    },
    getImage(name, index) {
      if (name == "全部") {
        if (this.classifyList[index].hover) {
          return this.quanbued;
        } else {
          return this.quanbu;
        }
      }
      if (name == "科技") {
        if (this.classifyList[index].hover) {
          return this.kejied;
        } else {
          return this.keji;
        }
      }
      if (name == "教材") {
        if (this.classifyList[index].hover) {
          return this.jiaocaied;
        } else {
          return this.jiaocai;
        }
      }
      if (name == "生活") {
        if (this.classifyList[index].hover) {
          return this.shenghuoed;
        } else {
          return this.shenghuo;
        }
      }
      if (name == "少儿") {
        if (this.classifyList[index].hover) {
          return this.shaoered;
        } else {
          return this.shaoer;
        }
      }
      if (name == "社科") {
        if (this.classifyList[index].hover) {
          return this.shekeed;
        } else {
          return this.sheke;
        }
      }
      if (name == "文艺") {
        if (this.classifyList[index].hover) {
          return this.wenyied;
        } else {
          return this.wenyi;
        }
      }
      if (name == "语言") {
        if (this.classifyList[index].hover) {
          return this.yuyaned;
        } else {
          return this.yuyan;
        }
      }
      if (name == "中小学教辅") {
        if (this.classifyList[index].hover) {
          return this.zongheed;
        } else {
          return this.zonghe;
        }
      }
    },
    changeImg(name, index) {
      this.classifyList[index].hover = true;
      let childrenlist = this.classifyList[index].children;
      if (childrenlist) {
        if (childrenlist.length > 9) {
          // this.seeAllNotice=true;
          this.treeHeight = childrenlist.length * 38;
        } else {
          this.treeHeight = 420;
          // this.seeAllNotice = false;
        }
      } else {
        this.treeHeight = 420;
        // this.seeAllNotice = false;
      }
      this.getImage(name, index);
      this.$forceUpdate();
    },
    outImg(name, index) {
      this.classifyList[index].hover = false;
      this.getImage(name, index);
      this.$forceUpdate();
    },
    //鼠标滚动事件
    mouseScroll() {
      // let scrollTop = document.querySelector(".twoClassContent").scrollTop;
      // let clientHeight = document.documentElement.clientHeight;//可视高度
      // let scrollHeight = document.documentElement.scrollHeight;//内容高度
      // this.seeAllNotice = false;
    },
    // 点击事件
    handleClick(val, name, index, idx, threeidx, fouridx) {
      if (val.id == -1) {
      } else {
        let queryForm = { subjectIds: [val.id] };
        this.$store.dispatch("setAllField", queryForm);
        this.$store.dispatch("setLeft", { subjectIds: name });
        this.$store.state.searchParam;
      }

      // let obj = { key: "subjectIds", value: [val.id] };
      // this.$store.dispatch("updateField", obj);
      this.$router.push({
        path: "/classify",
      });
      this.classifyFlag = false;
      // let indexArray = [index, idx, threeidx, fouridx];
      // this.$store.commit("TOP_ARRAY", indexArray);
    },
    classifyHover() {
      this.classifyFlag = true;
    },
    classifyOut() {
      this.classifyFlag = false;
    },
    // 打开网络许可证
    handleOpenLicence() {
      window.open(licenseImg);
    },
    // 打开备案信息
    handleOpenArchival(type) {
      if (type == 1) {
        window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002361");
      } else {
        window.open("https://beian.miit.gov.cn/");
      }
    },
    handleClickFirstClassify(item) {
      this.$store.dispatch("setLeft", { subjectIds: item.name });
      let obj = { key: "subjectIds", value: [item.id] };
      this.$store.dispatch("updateField", obj);
      this.$router.push({
        path: "/classify",
      });
    },
    // 首页检索
    handleSearch() {
      if (this.searchForm.searchWord.trim().length == 0) {
        this.$message.warning("请输入检索词");
        return;
      }
      // this.screeningData = this.$store.state.searchParam.searchNames;
      // let postBody = JSON.parse(JSON.stringify(this.searchForm));
      // postBody[postBody.searchField] = postBody.searchWord;
      // delete postBody.searchField;
      // delete postBody.searchWord;
      // this.$store.dispatch("setAllField", postBody);
      this.$store.dispatch("setAllField", this.searchForm);
      this.$router.push({
        path: "/classify",
        query: {
          searchForm: this.searchForm,
        },
      });
    },
    // 高级检索
    handleHighSearch() {
      this.$router.push({
        path: "/highSearch",
      });
    },
    // 获取首页数据
    getData() {
      indexSubjectCount().then(res => {
        let arr = res.data;
        let obj = {};
        arr.forEach(element => {
          obj[element.name] = element;
        });
        this.subjectCount = obj;
        console.log(this.subjectCount);
      });
      indexSubjectList().then(res => {
        if (res) {
          let treeData = handleTree(res.data);
          this.classifyList = treeData;
          this.classifyList.unshift({
            name: "全部",
            id: "-1",
          });
        }
      });
    },
    // 修改密码
    submitUpdatePassword() {
      this.$refs.updateForm.validate(valid => {
        if (valid) {
          getPublicKey().then(res => {
            const encrypt = new JSEncrypt();
            // 设置公钥（从后端获取并配置）
            const publicKey = `${res.data}`;
            encrypt.setPublicKey(publicKey);

            // 加密密码
            this.encryptedUsername = encrypt.encrypt(store.getters.name);
            this.encryptedOldPassword = encrypt.encrypt(this.updateForm.oldPassword);
            this.encryptedNewPassword = encrypt.encrypt(this.updateForm.newPassword);
            this.encryptedRequirePassword = encrypt.encrypt(this.updateForm.requirePassword);

            let postBody = {
              userName: Base64.encode(this.encryptedUsername),
              oldPassword: Base64.encode(this.encryptedOldPassword),
              newPassword: Base64.encode(this.encryptedNewPassword),
              requirePassword: Base64.encode(this.encryptedRequirePassword),
            };
            UpdatePwd(postBody).then(res => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.openUpdateView = false;
                sessionStorage.removeItem("updatePassword");
                this.$store.dispatch("LogOut").then(() => {
                  this.$router.push({
                    path: "/login",
                    query: {
                      redirect: this.$route.fullPath,
                      loginFlag: 1,
                    },
                  });
                });
              }
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classify {
  position: absolute;
  top: 70px;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 9999;
  font-size: 16px;
  border-top: 1px solid #078bef;
  padding: 20px 0 55px;
  // min-height: 410px;
  height: auto;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  ul {
    position: relative;
  }
  .oneClass {
    align-items: center;
    border-right: 1px dashed #dedede;
    width: 160px;
    padding-bottom: 15px;
    cursor: pointer;
    &:hover {
      color: #078bef;
      .twoClass {
        display: inline-block;
      }
    }
    &:last-child {
      padding: 0;
    }
    img {
      width: 18px;
      max-height: 18px;
      margin-right: 15px;
    }
    i {
      margin-left: 30px;
    }
    .twoClass {
      display: none;
      position: absolute;
      left: 150px;
      top: 0;
      width: 260px;
      border-right: 1px dashed #dedede;
      color: #333;
      text-align: left;
      .twoClassContent {
        width: 260px;
        min-height: 100%;
        // height: 310px;
        min-height: 310px;
        max-height: 100%;
        overflow-y: auto;
        scrollbar-color: #078bef #aaa;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          width: 1px;
          background-color: #aaa;
        }
        &::-webkit-scrollbar-thumb {
          background: #078bef;
        }
        .two-item {
          padding-left: 50px;
          // padding-right: 80px;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          &:last-child {
            padding-bottom: 0;
          }
          &:hover {
            color: #078bef;
            .threeClass {
              display: inline-block;
            }
          }
          .line1 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          i {
            margin-left: auto;
            margin-right: 70px;
          }
          .threeClass {
            display: none;
            position: absolute;
            left: 220px;
            top: 0;
            // padding-left: 40px;
            min-height: 100%;
            height: 330px;
            writing-mode: vertical-lr;
            // min-width: 600px;
            width: auto;
            color: #333;
            .flex {
              padding-left: 60px;
              flex-wrap: wrap;
              span {
                float: left;
                // width: 50px;
                writing-mode: horizontal-tb;
                margin-right: 80px;
                display: inline-block;
                margin-bottom: 15px;
                &:hover {
                  color: #078bef;
                }
              }
            }
          }
        }
      }
    }
  }
}
.linkurl {
  cursor: pointer;
  text-decoration: underline;
  margin-right: 15px;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}
.el-login-footer {
  height: 80px;
  // line-height: 40px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  // text-align: center;
  // color: #fff;
  // font-family: Arial;
  // font-size: 12px;
  letter-spacing: 1px;
  // opacity: 0.5;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
}
.container {
  width: 100%;
  height: calc(100vh - 71px);
  // height: calc(100vh - 187px);
  background: url("../../assets/images/bj.png") no-repeat;
  background-size: cover;
  //   align-items: center;
  // justify-content: center;
  padding-top: 30px;
  .w1200 {
    text-align: center;
    .search {
      align-items: center;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 100px;
      .searchForm {
        width: 640px;
        background: #fff;
        border: 4px solid #a8cdff;
        box-shadow: -6px 0px 39.48px 0.36px rgba(0, 98, 173, 0.5);
        border-radius: 40px;
        align-items: center;
        padding: 6px 0;
        margin-right: 10px;
        .el-select {
          margin-left: 20px;
          ::v-deep {
            .el-input__inner {
              border: none;
              width: 100px;
            }
          }
        }
        ::v-deep .el-input {
          flex: 1;
          .el-input__inner {
            border: none;
          }
        }
        img {
          margin-right: 30px;
          cursor: pointer;
        }
      }
      .heightSearch {
        cursor: pointer;
      }
    }
    .list {
      margin-top: 60px;
      ul {
        justify-content: space-between;
        li {
          position: relative;
          cursor: pointer;
          transition: all 0.5s linear;
          img {
            display: block;
          }
          .num {
            text-align: left;
            position: absolute;
            top: 30px;
            left: 40px;
            z-index: 999;
            p {
              &:nth-of-type(1) {
                font-size: 22px;
                // font-weight: bold;
                font-family: "黑体";
              }
              &:nth-of-type(2) {
                margin-top: 10px;
                color: #666666;
              }
            }
          }
          &:hover {
            transform: scale(1.05);
            p {
              color: #fff !important;
            }
            .hoverBg {
              transition: all 0.5s linear;
              opacity: 1;
            }
          }
          .hoverBg {
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 99;
          }
        }
      }
    }
  }
}
.question-mask {
  position: absolute;
  height: 20%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5), #fff);
  left: 150px;
  top: 90%;
  width: 260px;
  color: #999;
  text-align: center;
  span {
    line-height: 100px;
  }
}
.update-red {
  color: #ff0000;
  i {
    color: #fc5531;
    margin-right: 5px;
  }
}
.tip {
  color: #999;
  font-size: 12px;
}
</style>
